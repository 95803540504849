/* eslint-disable no-mixed-operators */
import { InfoOutlined } from '@ant-design/icons';
import { useEffect, useMemo, useState } from "react";
import { InfoBetTeaserTMP, InfoParlayBetsSlipTMP, InfoStrightBetsSlipTMP } from "../../../../api/betting/bettingApi";
import CaculateComponent from "./Caculate";
import { formatPrice, unit } from "../../../../until";
import useToast from "../../../../hook/useToast";
import useThrottle from "../../../../hook/useThrottle";
import ToastGlobal from '../../../../components/ToastGlobal';
import { warningBetslip } from '../../../../components/imgExport';


interface TotalStakeProps {
    decimal?: string
    keyBetTabs: string,
    numberStake: number,

    textWarningBetParlay?: string
    isWarningBetParlay?: boolean
    isWarningBetTeaser?: boolean
    showTotalStake: boolean,
    showTotalBet: boolean,
    totalOddsParlay?: number
    totalTParlay?: number
    totalAmountParlay?: number
    totalOddsTeaser?: number
    totalAmountTeaser?: number
    listAddStrightBetsSlip: InfoStrightBetsSlipTMP[]
    listParlayBetsSlip?: InfoParlayBetsSlipTMP[]
    listTeaserBetsSlip?: InfoBetTeaserTMP[]
    handleClick: (total: number) => void
    handleNumberClick: (total: number) => void
    showHideTotalStake: () => void,
    handleDeleteClick: (index: number) => void
    handleCheckBet: () => void
    handleCheckBetParlay?: () => void
    handleCheckBetTeaser?: () => void
    handleCheckQuantityMoneyStraight?: (keyBetTabs: number, total: number) => void
    hiddenBetPlace?: boolean,
}
const TotalStakeComponent = ({
    keyBetTabs, numberStake, textWarningBetParlay, isWarningBetParlay, isWarningBetTeaser,
    showTotalBet, showTotalStake,
    totalOddsParlay, totalTParlay, totalAmountParlay, totalAmountTeaser, totalOddsTeaser,
    listAddStrightBetsSlip, listParlayBetsSlip, listTeaserBetsSlip,
    handleClick, showHideTotalStake, handleNumberClick, handleDeleteClick, handleCheckBet, handleCheckBetParlay, handleCheckBetTeaser, handleCheckQuantityMoneyStraight,
    hiddenBetPlace
}: TotalStakeProps) => {

    const pushToast = useToast();

    const [totalAmount, setTotalAmount] = useState(0)
    const [totalWin, setTotalWin] = useState(0)
    const [totalBet, setTotalBet] = useState(0)
    const [totalBetParlay, setTotalBetParlay] = useState(0)
    const [totalBetTeaser, setTotalBetTeaser] = useState(0)
    const [warningParlay, setWarningParlay] = useState<boolean>(false)

    const checkBetParlay = useThrottle(() => {
        if (isWarningBetParlay)
            pushToast(textWarningBetParlay ? textWarningBetParlay : "Parlay cannot be placed as some of your legs cannot be combined", "warn");
        else {
            handleCheckBetParlay?.()
        }
    }, 1000)

    const checkBetTeaser = useThrottle(() => {
        if (isWarningBetTeaser)
            pushToast("Teaser cannot be placed as some of your legs cannot be combined", "warn");
        else {
            handleCheckBetTeaser?.()
        }
    }, 1000)

    const handleSuccess = () => {
        showHideTotalStake()
        switch (keyBetTabs) {
            case "1":
                handleCheckQuantityMoneyStraight?.(+keyBetTabs, +numberStake)
                break;
            case "2":
                handleCheckQuantityMoneyStraight?.(+keyBetTabs, Number(totalAmountParlay))
                break;
            case "3":
                handleCheckQuantityMoneyStraight?.(+keyBetTabs, Number(totalAmountTeaser))
                break;

            default:
                handleCheckQuantityMoneyStraight?.(+keyBetTabs, +numberStake)
                break;
        }

    }

    useEffect(() => {
        const totalWin = listAddStrightBetsSlip.reduce((accumulator, currentItem) => {
            return currentItem.isCheck ? (accumulator + Number(currentItem.win)) : accumulator;
        }, 0);

        setTotalWin(totalWin)
    }, [listAddStrightBetsSlip])

    useMemo(() => {
        const totalAmountTmp = listAddStrightBetsSlip.reduce((accumulator, currentItem) => {
            return currentItem.isCheck ? accumulator + currentItem.amount : accumulator;
        }, 0);
        setTotalAmount(totalAmountTmp)
        const totalWin = listAddStrightBetsSlip.reduce((accumulator, currentItem) => {
            return currentItem.isCheck ? (accumulator + Number(currentItem.win)) : accumulator;
        }, 0);
        setTotalWin(totalWin)
    }, [listAddStrightBetsSlip])

    useMemo(() => {
        const filteredArray = listAddStrightBetsSlip.filter((item) => item.isCheck);
        setTotalBet(filteredArray.length)
    }, [listAddStrightBetsSlip])

    useMemo(() => {
        const filteredArray = listParlayBetsSlip && listParlayBetsSlip.filter((item) => item.isCheck) || [];
        setTotalBetParlay(filteredArray.length)
    }, [listParlayBetsSlip])

    useMemo(() => {
        const filteredArray = listTeaserBetsSlip && listTeaserBetsSlip.filter((item) => item.isCheck) || [];
        setTotalBetTeaser(filteredArray.length)
    }, [listTeaserBetsSlip])

    useEffect(() => {
        setWarningParlay(false)
        if (totalBetParlay >= 2 && keyBetTabs === "2") {
            setTimeout(() => { setWarningParlay(isWarningBetParlay ?? false) }, 1000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isWarningBetParlay, totalBetParlay])

    return (
        <>
            <div
                className={`totalStake bg-[#131620] `}
                hidden={showTotalBet}
            >
                {/* (isWarningBetParlay && keyBetTabs === "2") && (totalBetParlay >= 2 && keyBetTabs === "2") */}
                <div
                    className={`border-warning-betslip rounded-[6px] p-1 text-left bg-[#39372E] flex justify-start items-center ${totalBetParlay >= 2 && isWarningBetParlay && warningParlay && keyBetTabs === "2" ? "!flex" : "!hidden"} `}
                // hidden={(!isWarningBetParlay && keyBetTabs === "2") || keyBetTabs === "3" || keyBetTabs === "1" || (totalBetParlay < 2 && keyBetTabs === "2")}
                >
                    <img src={warningBetslip} alt="" />
                    <span className="pl-[6px] text-left text-[#D4B962]">{textWarningBetParlay ? textWarningBetParlay : "Parlay cannot be placed as some of your legs cannot be combined"}</span>
                </div>
                <div
                    className="border-warning-betslip rounded-[6px] p-1 text-left bg-[#39372E] justify-start items-center"
                    hidden={(!isWarningBetTeaser && keyBetTabs === "3") || keyBetTabs === "1" || keyBetTabs === "2" || (totalBetTeaser < 2 && keyBetTabs === "3")}
                >
                    <img src={warningBetslip} alt="" />
                    <span className="pl-[6px] text-left text-[#D4B962]">Teaser cannot be placed as some of your selections cannot be combined</span>
                </div>
                <div
                    className="rounded-[6px] p-2 mt-4 text-left pl-3 border-[#D4B962] border-solid border-[1px] bg-transparent text-[#D4B962]"
                    hidden={(totalBetParlay > 1 && keyBetTabs === "2") || (totalBetTeaser > 1 && keyBetTabs === "3") || keyBetTabs === "1"}
                >
                    <InfoOutlined className="bg-[#D4B962] p-[1px] rounded-circle text-white" />
                    <span className="pl-[6px] text-left">Select at least 2 combinable picks</span>
                </div>

                <div className='flex justify-between items-center h-14'>
                    <span className='font-bold text-[16px] text-white'>{
                        keyBetTabs === "1" ? `Stake per bet` : `Total stake`}</span>
                    {
                        keyBetTabs === "1" &&
                        <span className='font-bold text-[14px] h-[32px] stakeButton text-white' onClick={showHideTotalStake}>
                            {numberStake === 0 ? 'Total Stake' : `${unit()}${numberStake}`}
                        </span>
                    }
                    {
                        keyBetTabs === "2" &&
                        <span className='font-bold text-[14px] h-[32px] stakeButton text-white' onClick={showHideTotalStake}>
                            {totalAmountParlay === 0 ? 'Total Stake' : `${unit()}${totalAmountParlay}`}
                        </span>
                    }
                    {
                        keyBetTabs === "3" &&
                        <span className='font-bold text-[14px] h-[32px] stakeButton text-white' onClick={showHideTotalStake}>
                            {totalAmountTeaser === 0 ? 'Total Stake' : `${unit()}${totalAmountTeaser}`}
                        </span>
                    }
                </div>
                <CaculateComponent
                    showTotalStake={showTotalStake}
                    handleClick={handleClick}
                    handleNumberClick={handleNumberClick}
                    handleDeleteClick={handleDeleteClick}
                    handleSuccess={handleSuccess}
                // handleSuccess={showHideTotalStake}
                />
                {/* <div className={`${hiddenBetPlace ? 'hidden' : ''}`}> */}
                <div>
                    {
                        keyBetTabs === "1" &&
                        <>
                            <span
                                className={`flex justify-start pb-5 text-white ${totalBet > 1 ? '' : 'hidden'}`}
                            >
                                {totalBet} Bets
                            </span>
                            <div>

                                <div className="flex justify-between">
                                    <div className='flex flex-col justify-between pb-5'>
                                        <span className=' text-white text-base'>Total stake</span>
                                        <span className='font-bold text-white text-base'>{unit()}{formatPrice(totalAmount || 0)}</span>
                                    </div>
                                    <div className='flex flex-col justify-between pb-5'>
                                        <span className=' text-white text-base'>Total win</span>
                                        <span className='font-bold text-[#33E49A] text-base'>{totalWin === 0 ? `${unit()}0` : `${unit()}${formatPrice(Math.round(totalWin) || 0)}`}</span>
                                    </div>
                                </div>
                                <div>
                                    <button className="font-bold rounded-[2px]  uppercase w-full  py-[10px] !bg-[#D4B962] !text-[#20273D]" onClick={handleCheckBet}>Place bet</button>
                                </div>
                            </div>
                        </>

                    }
                    {
                        keyBetTabs === "2" &&
                        <>
                            <span
                                className={`flex justify-start pb-5 text-white ${totalBetParlay > 1 ? '' : 'hidden'}`}
                            >
                                {totalBetParlay} Bets
                            </span>
                            <div>
                                <div className="flex  justify-between">
                                    <div className='flex flex-col justify-between pb-5 text-white'>
                                        <span className='text-base'>Total odds</span>
                                        <span className='font-bold text-base'>{formatPrice(Number(totalOddsParlay || 0).toFixed(2))}</span>
                                    </div>
                                    <div className='flex flex-col justify-between pb-5 text-white'>
                                        <span className='text-base'>Total win</span>
                                        <span className='font-bold text-base text-[#33E49A]'>{totalAmountParlay === 0 ? `${unit()}0` : `${unit()}${formatPrice(Math.round(((totalAmountParlay || 0) + (totalAmountParlay || 0) * ((totalOddsParlay || 1) - 1 || 0))) || 0)}`}</span>
                                        {/* <span className='font-bold text-[#33E49A]'>{totalAmountParlay === 0 ? `${unit()}0` : `${unit()}${formatPrice(Math.round(((totalAmountParlay || 0) + (totalAmountParlay || 0) * ((totalOddsParlay || 1) - 1 || 0) * (totalTParlay || 1))) || 0)}`}</span> */}
                                    </div>
                                </div>

                                <div
                                >
                                    {!isWarningBetParlay && (
                                        <button className="font-bold rounded-[2px]  uppercase w-full  py-[10px] !bg-[#D4B962] !text-[#20273D]" onClick={checkBetParlay}>Place bet</button>
                                    )}
                                </div>
                            </div>
                        </>
                    }
                    {
                        keyBetTabs === "3" &&
                        <>
                            <span
                                className={`flex justify-start pb-5 text-white ${totalBetParlay > 1 ? '' : 'hidden'}`}
                            >
                                {totalBetParlay} Bets
                            </span>
                            <div>
                                <div className='flex justify-between pb-5'>
                                    <span>Total odds</span>{ }
                                    <span className='font-bold'>{formatPrice(Number(totalOddsTeaser) || 0)}</span>
                                </div>
                                <div className='flex justify-between pb-5'>
                                    <span>Total win</span>
                                    <span className='font-bold text-[#33E49A]'>{totalAmountTeaser === 0 ? `${unit()}0` : `${unit()}${formatPrice(Math.round(((totalAmountTeaser || 0) * (totalOddsTeaser || 0))) || 0)}`}</span>
                                </div>
                                <div
                                >
                                    {!isWarningBetParlay && (
                                        <button className="font-bold rounded-[2px]  uppercase w-full  py-[10px] !bg-[#D4B962] !text-[#20273D]" onClick={checkBetTeaser}>Place bet</button>
                                    )}
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div >
            <div className="w-[304px] toast-component-global">
                <ToastGlobal />
            </div>
        </>
    )
}
export default TotalStakeComponent