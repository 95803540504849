const getToken = () => {
    // return localStorage.getItem('token');
    const cookies = document.cookie.split("; ");
    for (let cookie of cookies) {
        const [key, value] = cookie.split("=");
        if (key === 'token') {
            return decodeURIComponent(value);
        }
    }
    return null;
}

const getTokenNew = () => {
    return '12345678-abcd-1234-ef00-1234567890ab';
}

const setToken = (token: string) => {
    document.cookie = "token=" + token + "; expires=Fri, 31 Dec 2500 23:59:59 GMT; path=/";
    // localStorage.setItem('token', token);
}

const removeToken = () => {
    // localStorage.removeItem('token');
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";

}

export default {
    getToken, getTokenNew, setToken, removeToken
}